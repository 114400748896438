import PropTypes from "prop-types"
import React from "react"


/**
 * Horizontal line with color used to separate content
 */
const ColoredSeparator = ({ color, customWrapperCname }) => (
	<hr
		className={customWrapperCname}
		style={{
			color: color,
			backgroundColor: color,
			height: "2.5px",
			width: "100%"
		}}
	/>
);

ColoredSeparator.propTypes = {
	color: PropTypes.string,
}

ColoredSeparator.defaultProps = {
	color: 'black',
}

export default ColoredSeparator
