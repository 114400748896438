import React from "react"
import Layout from "../components/layout"
import * as styles from "./cookie_policy.module.css"
import Seo from "../components/seo"
import { Link } from "gatsby"
import ColoredSeparator from "../components/colored_sep"


const sidep_link_style = {
	color: "#F15B5B",
	fontWeight: "bold",
	textDecoration: "none",
}


const MainP = () => {
	return (
		<>
		<div style={{marginTop:"50px"}}>
			<h1>Cookie Policy</h1>
			<p>
				A cookie is a small data file made up of letters and numbers which is placed by a website on the device you use to access the Internet.
				Cookies serve different purposes, and may be tied to personal information.
		    </p>
			<p>
				To find out more about cookies, including how to see what cookies have been set and how to block, manage, and delete them,
				please visit <a href="https://www.allaboutcookies.org/manage-cookies/" target="_blank" rel="noopener noreferrer" style={sidep_link_style}> www.allaboutcookies.org/manage-cookies/</a>.
		    </p>

			<h2>Types of cookies used on this portfolio</h2>

			<p>
				<b>Persistent cookie</b><br/>
				Instead of expiring when the web browser is closed as session cookies do,
				a persistent cookie expires at a specific date or after a specific length of time.
				This means that, for the cookie's entire lifespan (which can be as long or as short as its creators want),
				its information will be transmitted to the server every time the user visits the website that it belongs to,
				or every time the user views a resource belonging to that website from another website (such as an advertisement).

				For this reason, persistent cookies are sometimes referred to as tracking cookies
				because they can be used by advertisers to record information about a user's web browsing habits over an extended period of time.
				However, they are also used for "legitimate" reasons (such as keeping users logged into their accounts on websites,
				to avoid re-entering login credentials at every visit).
			</p>

			<p>
				Here's a list of cookies used on this web site:
			</p>
			<h3>Google Analytics</h3>
			<p>
				Cookies: _ga, _gid <br/>

				Google Analytics are used to understand how you interact with the website and therefore to improve eventual
				ad campaigns promoted on social media.<br/>
				To opt out of being tracked via Google Analytics, you can also use Google's opt-out browser add-on:&nbsp;
				<a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer" style={sidep_link_style}>tools.google.com/dlpage/gaoptout</a>.
			</p>
			<h3>Control Cookie</h3>
			<p>
				Cookie: gdpr-analytics-enabled <br/>

				Control cookie used to keep track of user's decision, when cookie banner is promped on first site visit.
			</p>
		</div>
		</>

	)
}


const CookiePolicyPage = () => {
	React.useEffect( () => {
		if( typeof window !== "undefined" )
			window.scrollTo( 0, 0 )
	}, [])

	return (
		<Layout>
			<Seo title="Info" subTitle="Cookie Policy"/>
			<div className={styles.pageWrapper}>
				<div className={styles.grid}>
					<div className={styles.cellitem}>
						{<MainP/>}
					</div>

					<div className={styles.cellitem + " " + styles.backLink_wrap}>
						<Link to="/" className={styles.backLink}>BACK TO HOME PAGE</Link>
						<ColoredSeparator color="black" />
					</div>
				</div>
			</div>

		</Layout>
	)
}


export default CookiePolicyPage
